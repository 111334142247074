import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../utils/firebase";
import Loader from "../Loader/Loader";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const TotalCalls = ({ salesRepresentative }) => {
  const [graphData, setGraphData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { cid } = useParams();

  function mapCalls(data) {
    const dataObj = [];
    let temp = {
      "Order Confirmed": 0,
      Lead: 0,
      "No Order": 0,
      "Not Available": 0,
    };
    data?.forEach((call) => {
      temp[call.status] += 1;
    });
    for (let i = 0; i < Object.keys(temp).length; i++) {
      dataObj.push({
        name: Object.keys(temp)[i],
        Calls: Object.values(temp)[i],
      });
    }
    setGraphData(dataObj);
  }

  useEffect(() => {
    const fetchCalls = () => {
      db.collection("callhistory")
        .where("agent_id", "==", cid)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => doc.data());
          //   setCalls(data);
          mapCalls(data);
          setIsLoading(false);
        });
    };
    fetchCalls();
  }, [cid]);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="performance-graph-div">
          <BarChart
            width={600}
            height={300}
            data={graphData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Calls" fill="#82ca9d" />
          </BarChart>
        </div>
      )}
    </div>
  );
};

export default TotalCalls;
