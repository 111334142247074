import { useState, useContext, useCallback, memo } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import ConfirmBox from "../ConfirmBox/ConfirmBox";
import logo from "../../assets/logo.png";
import "./NavigationBar.css";

const NavigationBar = () => {
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogOut = useCallback(() => {
    localStorage.removeItem("currentUser");
    setCurrentUser(null);
    navigate("/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ConfirmBox
        show={showConfirmBox}
        setShowConfirmBox={setShowConfirmBox}
        logoutFunction={handleLogOut}
        text="Are you sure you want to logout?"
      />

      <Navbar
        bg="light"
        variant="light"
        collapseOnSelect
        expand="lg"
        style={{
          boxShadow: "0 2px 4px 0 rgb(0 0 0 / 10%), 0 2px 5px 0 rgb(0 0 0 / 5%)",
        }}
      >
        <Container style={{ margin: "auto", padding: 0 }}>
          <Navbar.Brand onClick={() => navigate("/")}>
            <img src={logo} alt="logo" width={175} style={{ cursor: "pointer" }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" style={{ flexGrow: 0 }}>
            <Nav>
              <NavLink to="/" className={({ isActive }) => (isActive ? "active__link" : null)}>
                Home
              </NavLink>
              <NavLink
                to="/scheduled-calls"
                className={({ isActive }) => (isActive ? "active__link" : null)}
              >
                Scheduled Calls
              </NavLink>
              <NavLink
                to="/customers"
                className={({ isActive }) => (isActive ? "active__link" : null)}
              >
                Customers
              </NavLink>
              <NavLink
                to="/orders"
                className={({ isActive }) => (isActive ? "active__link" : null)}
              >
                Orders
              </NavLink>
              <NavLink
                to="/product-groups"
                className={({ isActive }) => (isActive ? "active__link" : null)}
              >
                Product Groups
              </NavLink>
              <NavLink to="/roles" className={({ isActive }) => (isActive ? "active__link" : null)}>
                Roles
              </NavLink>
              {(currentUser?.role === "admin" || currentUser?.role === "super admin") && (
                <NavLink
                  to="/sales-representatives"
                  className={({ isActive }) => (isActive ? "active__link" : null)}
                >
                  Sales Representatives
                </NavLink>
              )}
              {currentUser?.role === "super admin" && (
                <NavLink
                  to="/admins"
                  className={({ isActive }) => (isActive ? "active__link" : null)}
                >
                  Admins
                </NavLink>
              )}
              <Button
                variant="success"
                onClick={() => setShowConfirmBox(true)}
                className="logout__btn"
              >
                Logout
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default memo(NavigationBar);
