function removeVAT(price) {
  return price - price * (18 / 118);
}

function calculateSubtotal(products) {
  let sum = 0;
  for (let i = 0; i < products.length; i++) {
    sum +=
      removeVAT(Number(products[i].price.replaceAll(",", ""))) *
      Number(products[i].quantity.replaceAll(",", ""));
  }
  return sum;
}

function calculateVAT(products) {
  let sum = 0;
  for (let i = 0; i < products.length; i++) {
    sum +=
      Number(products[i].price.replaceAll(",", "")) *
      Number(products[i].quantity.replaceAll(",", ""));
  }
  return (18 / 118) * sum;
}

function calculateTotal(products) {
  const total =
    calculateSubtotal(products, false) + calculateVAT(products, false);
  return total;
}

const ProductsTable = ({ pfiCall, isVatApplicable }) => {
  return (
    <div className="product_table">
      {pfiCall?.status === "Lead" && (
        <table>
          <thead>
            <tr>
              <th style={{ width: "10%" }}>QTY</th>
              <th style={{ width: "50%" }}>Product Description</th>
              <th style={{ width: "15%" }}>Price</th>
              <th style={{ width: "20%" }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {pfiCall?.productsRequired?.map((product) => (
              <tr key={product?.id}>
                <td>{product?.quantity}</td>
                <td>{product?.name}</td>
                <td>
                  {Math.round(
                    removeVAT(Number(product?.price.replaceAll(",", "")))
                  ).toLocaleString()}
                  .00
                </td>
                <td>
                  {Math.round(
                    removeVAT(
                      Number(product?.quantity.replaceAll(",", "")) *
                        Number(product?.price.replaceAll(",", ""))
                    )
                  ).toLocaleString()}
                  .00
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={3} style={{ textAlign: "right" }}>
                Subtotal:
              </td>
              <td>
                <b>
                  Tsh{" "}
                  {Math.round(
                    calculateSubtotal(pfiCall?.productsRequired)
                  ).toLocaleString()}
                  .00
                </b>
              </td>
            </tr>
            <tr>
              <td colSpan={3} style={{ textAlign: "right" }}>
                VAT:
              </td>
              <td>
                <b>
                  {isVatApplicable
                    ? "Tsh " +
                      Math.round(
                        calculateVAT(pfiCall?.productsRequired)
                      ).toLocaleString() +
                      ".00"
                    : "EXEMPTED"}
                </b>
              </td>
            </tr>
            <tr>
              <td colSpan={3} style={{ textAlign: "right" }}>
                Total:
              </td>
              <td>
                <b>
                  Tsh{" "}
                  {Math.round(
                    isVatApplicable
                      ? calculateTotal(pfiCall?.productsRequired)
                      : calculateSubtotal(pfiCall?.productsRequired)
                  ).toLocaleString()}
                  .00
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ProductsTable;
