import { createContext, useState, useEffect } from "react";
import Loader from "../components/Loader/Loader";
import { db } from "../utils/firebase";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [uniqueTins, setUniqueTins] = useState(undefined);
  const [uniquePhoneNumbers, setUniquePhoneNumbers] = useState(undefined);

  const fetchUniqueTins = () => {
    db.collection("unique")
      .doc("tin")
      .get()
      .then((doc) => setUniqueTins(doc.data().value))
      .catch((err) => console.log(err.message));
  };
  const fetchUniquePhoneNumbers = () => {
    db.collection("unique")
      .doc("phone")
      .get()
      .then((doc) => setUniquePhoneNumbers(doc.data().value))
      .catch((err) => console.log(err.message));
  };

  useEffect(() => {
    const checkAuth = () => {
      setIsLoading(true);
      const user = localStorage.getItem("currentUser");
      if (user) {
        setCurrentUser(JSON.parse(user));
      } else {
        setCurrentUser(null);
      }
      setIsLoading(false);
    };

    checkAuth();
    fetchUniqueTins();
    fetchUniquePhoneNumbers();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        uniqueTins,
        uniquePhoneNumbers,
        fetchUniqueTins,
        fetchUniquePhoneNumbers,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
