import { memo, useCallback, useState } from "react";
import { db } from "../../utils/firebase";
import EditAdmins from "./EditAdmins";
import ConfirmBox from "../ConfirmBox/ConfirmBox";
import { Table } from "react-bootstrap";
import { BsTrashFill } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";

const AdminsTable = ({ admins }) => {
  const [isEditingAdmin, setIsEditingAdmin] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(-1);
  const [showConfirmBox, setShowConfirmBox] = useState(false);

  const deleteAdmin = useCallback((id) => {
    db.collection("users").doc(id).delete();
    setShowConfirmBox(false);
  }, []);

  return (
    <>
      {isEditingAdmin && (
        <EditAdmins
          admin={admins[selectedAdmin]}
          setIsEditingAdmin={setIsEditingAdmin}
          isEditingAdmin={isEditingAdmin}
        />
      )}
      {showConfirmBox && (
        <ConfirmBox
          show={showConfirmBox}
          setShowConfirmBox={setShowConfirmBox}
          deleteFunction={deleteAdmin}
          id={admins[selectedAdmin]?.id}
          text="Are you sure you want to delete this?"
        />
      )}
      <Table responsive striped bordered>
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Name</th>
            <th>Username</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>DOB</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {admins?.map((admin, index) => (
            <tr key={admin.id}>
              <td>{index + 1}.</td>
              <td>{admin.name}</td>
              <td>{admin.username}</td>
              <td>{admin.email}</td>
              <td>
                {admin.phonenumber}
                {admin.phonenumber2 && `, ${admin.phonenumber2}`}
                {admin.phonenumber3 && `, ${admin.phonenumber3}`}
              </td>
              <td>{admin.dob || "Not Added"}</td>
              <td>
                <div className="actions__btn__container">
                  <button
                    onClick={() => {
                      setIsEditingAdmin(true);
                      setSelectedAdmin(index);
                    }}
                    className="action__btn"
                  >
                    <MdModeEdit />
                  </button>
                  &nbsp;&nbsp;
                  <button
                    onClick={() => {
                      setSelectedAdmin(index);
                      setShowConfirmBox(true);
                    }}
                    className="action__btn"
                  >
                    <BsTrashFill />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default memo(AdminsTable);
