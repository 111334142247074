/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, memo } from "react";
import { Badge, Container, Modal, Row, Table } from "react-bootstrap";
import dateFormat from "dateformat";
import { db } from "../../utils/firebase";

const ALL_CATEGORIES = {};

const CallDetailsModal = ({ show, setShowCallDetails, customer, lastCallDetails }) => {
  const [status, setStatus] = useState("");
  const [allInterestedProductGroups, setAllInterestedProductGroups] = useState({});

  useEffect(() => {
    let abortController = new AbortController();
    db.collection("productgroups")
      .orderBy("name", "asc")
      .onSnapshot((snapshot) => {
        snapshot.docs.forEach((doc) => {
          const { id, name } = doc.data();
          ALL_CATEGORIES[id] = name;
        });
        setAllInterestedProductGroups(ALL_CATEGORIES);
      });
    return () => abortController.abort();
  }, []);

  useEffect(() => {
    if (lastCallDetails?.status === "Lead") {
      setStatus("success");
    } else if (lastCallDetails?.status === "No Order") {
      setStatus("danger");
    } else if (lastCallDetails?.status === "Not Available") {
      setStatus("warning");
    } else if (lastCallDetails?.status === "Order Confirmed") {
      setStatus("success");
    }
  }, []);

  return (
    <Modal
      show={show}
      onHide={() => setShowCallDetails(false)}
      animation={false}
      centered
      className="big__modal"
    >
      <Modal.Header closeButton style={{ borderBottom: "none", paddingBottom: 0 }}>
        <Modal.Title>
          Call Details of{" "}
          {customer?.customerType === "Company"
            ? customer?.companyName ?? "(deleted)"
            : customer?.contactPersons[0]?.name ?? "(deleted)"}
          &nbsp;
          <Badge bg={status}>{lastCallDetails?.status}</Badge>
          <br />
          {lastCallDetails?.updatedAt && (
            <span style={{ fontSize: 16, fontWeight: 400 }}>
              last called on&nbsp;
              {dateFormat(
                new Date(lastCallDetails?.updatedAt?.seconds * 1000),
                "ddd, dd mmm  yyyy, hh:MM TT",
              )}
            </span>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container style={{ margin: 0 }}>
          {/* Feedback Status */}
          <Row className="mb-1">
            <h5 style={{ marginBottom: 5 }}>Feedback Status</h5>
            <p className="mb-0">
              {lastCallDetails.status === undefined ? "Order Confirmed" : lastCallDetails?.status}
            </p>
          </Row>
          {/* Required Products for Leads */}
          <Row className="mb-1">
            {lastCallDetails?.status === "Lead" && (
              <>
                <h5 style={{ marginBottom: 10 }}>Required Products</h5>
                <Table responsive striped bordered>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lastCallDetails?.productsRequired?.map((product) => (
                      <tr key={product?.id}>
                        <td>{product?.name}</td>
                        <td>
                          {new Intl.NumberFormat("en-TZ", {
                            style: "currency",
                            currency: "TZS",
                          }).format(Number(product?.price.replaceAll(",", "")))}
                        </td>
                        <td>{product?.quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </Row>

          {/* No Order Products */}
          <Row className="mb-1">
            {lastCallDetails?.status === "No Order" && lastCallDetails?.isRequirement && (
              <>
                <h5 style={{ marginBottom: 10 }}>Product Requirement</h5>
                <Table striped responsive bordered>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lastCallDetails?.noOrderProducts?.map((product) => (
                      <tr key={product?.id}>
                        <td>{product?.name}</td>
                        <td>
                          {new Intl.NumberFormat("en-TZ", {
                            style: "currency",
                            currency: "TZS",
                          }).format(Number(product?.price.replaceAll(",", "")))}
                        </td>
                        <td>{product?.quantity}</td>
                        <td>{product?.remarks}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </Row>
          {/* Order Confirmed Required Products */}
          <Row className="mb-1">
            {(lastCallDetails?.status === "Order Confirmed" ||
              lastCallDetails?.status === undefined) && (
              <>
                <h5 style={{ marginBottom: 10 }}>Product Requirement</h5>
                <Table responsive striped bordered>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lastCallDetails?.productsRequired?.map((product) => (
                      <tr key={product?.id}>
                        <td>{product?.name}</td>
                        <td>
                          {new Intl.NumberFormat("en-TZ", {
                            style: "currency",
                            currency: "TZS",
                          }).format(Number(product?.price.replaceAll(",", "")))}
                        </td>
                        <td>{product?.quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </Row>

          {/* related Product groups */}
          {(lastCallDetails?.status !== "Not Available" ||
            lastCallDetails?.status !== undefined) && (
            <Row className="mb-1">
              <h5 style={{ marginBottom: 5 }}>Related Product Groups</h5>
              <p>
                {lastCallDetails?.relatedProductGroups
                  ? lastCallDetails?.relatedProductGroups
                      ?.map((ipg) => allInterestedProductGroups[ipg])
                      .join(", ")
                  : "N/A"}
              </p>
            </Row>
          )}

          {/* Discussion */}
          <Row className="mb-1">
            <h5 style={{ marginBottom: 5 }}>Discussion</h5>
            <p>{lastCallDetails?.discussion}</p>
          </Row>

          {/* Scheduled Call Time */}
          {lastCallDetails.status !== "Order Confirmed" && (
            <Row className="mb-1">
              <h5 style={{ marginBottom: 5 }}>Scheduled Call Time</h5>
              <p>
                {lastCallDetails?.isCallback
                  ? dateFormat(
                      new Date(lastCallDetails?.callback_date?.seconds * 1000),
                      "ddd, dd mmm  yyyy, hh:MM TT",
                    )
                  : "No call scheduled"}
              </p>
            </Row>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default memo(CallDetailsModal);
