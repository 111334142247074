import { memo, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { auth, db } from "../utils/firebase";
import { AuthContext } from "../context/authContext";
import { Button, Form, Modal } from "react-bootstrap";
import { MdPerson, MdLock } from "react-icons/md";
import { BiErrorCircle } from "react-icons/bi";
import logo from "../assets/logo.png";
import "../styles/Login.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showMsg, setShowMsg] = useState("");
  const { setCurrentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const addCurrentUser = (user) => {
    const loggedInUser = {
      id: user.id,
      username: user.username,
      name: user.name,
      email: user.email,
      phonenumber: user.phonenumber,
      role: user.role,
    };
    setCurrentUser(loggedInUser);
    localStorage.setItem("currentUser", JSON.stringify(loggedInUser));
    navigate("/");
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage(false);
    // if (username === "admin" && password === "admin@123") {
    //   addCurrentUser({ username: "admin", name: "admin", role: "admin" });
    //   return;
    // }
    if (password === "changepassword") {
      setShowForgotPasswordModal(true);
      setPassword("");
      setUsername("");
      return;
    }
    try {
      if (
        username === "ali@marango.co.tz" ||
        username === "keshkamatparam@gmail.com" ||
        username === "test@test.com"
      ) {
        const user = await auth.signInWithEmailAndPassword(username, password);
        if (user) {
          addCurrentUser({
            id: user?.user?.multiFactor.user.uid,
            email: user?.user?.multiFactor.user.email,
            role: "super admin",
            username: user?.user?.multiFactor.user.email.split("@")[0],
            name: user?.user?.multiFactor.user.email.split("@")[0],
          });
          return;
        }
      } else {
        db.collection("users")
          .where("username", "==", username)
          .where("password", "==", CryptoJS.SHA256(password).toString(CryptoJS.enc.Base64))
          .onSnapshot((snapshot) => {
            if (snapshot.docs.length === 0) {
              setErrorMessage(true);
              return;
            }
            addCurrentUser(snapshot.docs[0].data());
          });
      }
    } catch (err) {
      console.log(err.message);
      setErrorMessage(true);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setErrorMsg("");
    if (!forgotPasswordEmail) {
      return;
    }
    if (
      forgotPasswordEmail === "ali@marango.co.tz" ||
      forgotPasswordEmail === "keshkamatparam@gmail.com"
    ) {
      await auth.sendPasswordResetEmail(forgotPasswordEmail);
      setShowMsg("Reset password link has been sent to your email.");
    } else {
      setErrorMsg("Please enter a registered Email");
    }
  };

  return (
    <>
      <div className="login__container">
        <form onSubmit={handleLogin}>
          <img src={logo} alt="logo" />
          <div className="form__row">
            <MdPerson />
            <input
              type="test"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form__row">
            <MdLock />
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {errorMessage && (
            <p className="error__msg">
              <BiErrorCircle />
              &nbsp;Invalid credentials
            </p>
          )}
          <div className="btn__container">
            <div className="show__password">
              <p style={{ marginBottom: 0 }}>Show Password</p>
              &nbsp;&nbsp;
              <Form.Check
                type="switch"
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
              />
            </div>
            <button type="submit" className="btn btn-success">
              Login
            </button>
          </div>
        </form>
      </div>

      <Modal
        show={showForgotPasswordModal}
        onHide={() => setShowForgotPasswordModal(false)}
        animation={false}
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: "none", paddingBottom: 0 }}>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleForgotPassword}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Enter your email"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              {errorMsg && (
                <p className="error__msg" style={{ marginBottom: 20 }}>
                  <BiErrorCircle />
                  &nbsp;{errorMsg}
                </p>
              )}
              {showMsg && (
                <p className="" style={{ marginBottom: 20 }}>
                  {showMsg}
                </p>
              )}
            </Form.Group>
            {!showMsg && (
              <Modal.Footer style={{ padding: 0, borderTop: "none" }}>
                <Form.Group>
                  <Button
                    variant="danger"
                    type="button"
                    onClick={() => setShowForgotPasswordModal(false)}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button variant="success" type="submit">
                    Submit
                  </Button>
                </Form.Group>
              </Modal.Footer>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(Login);
