import { memo } from "react";
import SingleCallHistoryRow from "./SingleCallHistoryRow";
import { Table } from "react-bootstrap";

const SingleCallHistoryTable = ({ customerCalls }) => {
  return (
    <Table responsive striped bordered>
      <thead>
        <tr>
          <th>Sr No.</th>
          <th>Name</th>
          <th>Phone Number</th>
          <th>Status</th>
          <th>Call Time</th>
          <th>Sales Representative</th>
          <th>Details</th>
        </tr>
      </thead>
      <tbody>
        {customerCalls?.map((call, index) => (
          <SingleCallHistoryRow call={call} key={call.id} index={index + 1} />
        ))}
      </tbody>
    </Table>
  );
};

export default memo(SingleCallHistoryTable);
