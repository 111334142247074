/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, memo } from "react";
import { db, fieldValue, timestamp } from "../../utils/firebase";
import ContactPersons from "./ContactPersons";
import MyCheckbox from "../MyCheckbox/MyCheckbox";
import { v4 as uuidv4 } from "uuid";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { countries } from "../../utils/countries";
import { BiErrorCircle } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import "../../styles/Customers.css";

const EditCustomers = ({
  show,
  setIsEditingCustomer,
  customer,
  allCategories,
}) => {
  const [customerType, setCustomerType] = useState(customer.customerType);
  const [companyName, setCompanyName] = useState(customer.companyName);
  const [personalDetails, setPersonalDetails] = useState(
    customer.customerType === "Individual" ? customer.contactPersons : []
  );
  const [employeeDetails, setEmployeeDetails] = useState(
    customer.customerType === "Company" ? customer.contactPersons : []
  );
  const [address, setAddress] = useState(customer.address);
  const [tin_no, setTin_no] = useState(customer.TIN);
  const [vrn_no, setVrn_no] = useState(customer.VRN);
  const [interestedProductGroups, setInterestedProductGroups] = useState(
    customer.interestedProductGroups
  );
  const [otherProductGroups, setOtherProductGroups] = useState(
    customer.otherProductGroups
  );
  const [allProductsGroupsSelected, setAllProductsGroupsSelected] = useState(
    allCategories.length === customer.interestedProductGroups.length
      ? true
      : false
  );

  const [errorMsg, setErrorMsg] = useState("");
  const [allProductGroups, setAllProductGroups] = useState([]);
  const [allCountries, setAllCountries] = useState();
  const [allCities, setAllCities] = useState([]);

  // Function to fetch cities of selected Country
  const fetchCitiesByCountry = async (countryCode) => {
    try {
      const response = await fetch(
        `https://api.countrystatecity.in/v1/countries/${countryCode}/cities`,
        {
          method: "GET",
          headers: {
            "X-CSCAPI-KEY":
              "emh5c0xMYkxaenU2Z2V3a3lyOExyQjliRXR5bktkeG9LOFNnVjh3NQ==",
          },
          redirect: "follow",
        }
      );
      const data = await response.json();
      setAllCities(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  // get iso2 of selected Country
  const getSelectedCountryId = () => {
    const selectedCountry = countries.filter(
      (country) => country.name === address.country
    );
    return selectedCountry[0]?.iso2;
  };

  // Function to edit customer
  const handleEdit = async (e) => {
    e.preventDefault();
    setErrorMsg("");
    if (customerType === "Company" && !companyName) {
      setErrorMsg("Company Name is Required.");
      return;
    } else if (!address.physical_address) {
      setErrorMsg("All Address Fields are required.");
      return;
    } else if (interestedProductGroups.length < 1 && !otherProductGroups) {
      setErrorMsg("Please Enter Interested Product Group Details.");
      return;
    } else if (interestedProductGroups.length === 0) {
      setErrorMsg("Please Enter Interested Product Group Details.");
      return;
    } else if (
      customerType === "Company" &&
      (!employeeDetails[employeeDetails.length - 1].name ||
        !employeeDetails[employeeDetails.length - 1].phoneNumber1)
    ) {
      setErrorMsg("Please fill Name and Phone Number of Employee.");
      return;
    } else if (
      customerType === "Individual" &&
      (!personalDetails[personalDetails.length - 1].name ||
        !personalDetails[personalDetails.length - 1].phoneNumber1)
    ) {
      setErrorMsg("Please fill Name and Phone Number of Customer.");
      return;
    } else {
      const obj = {
        companyName,
        otherProductGroups,
        address,
        updatedAt: timestamp,
        customerType,
        interestedProductGroups,
        TIN: tin_no,
        VRN: vrn_no,
        contactPersons:
          customerType === "Individual"
            ? personalDetails[personalDetails.length - 1].name === ""
              ? personalDetails.slice(0, personalDetails.length - 1)
              : personalDetails
            : employeeDetails[employeeDetails.length - 1].name === ""
            ? employeeDetails.slice(0, employeeDetails.length - 1)
            : employeeDetails,
      };
      db.collection("customers")
        .doc(customer.id)
        .update(obj)
        .then(() => {
          const phone_numbers =
            customerType === "Individual"
              ? personalDetails.map((pd) => pd.phoneNumber1)
              : employeeDetails.map((ed) => ed.phoneNumber1);
          db.collection("unique")
            .doc("phone")
            .update({
              value: fieldValue.arrayUnion(...[...phone_numbers]),
            });
          tin_no &&
            db
              .collection("unique")
              .doc("tin")
              .update({
                value: fieldValue.arrayUnion(...[tin_no]),
              });
        });
      setIsEditingCustomer(false);
    }
  };

  useEffect(() => {
    let abortController = new AbortController();
    db.collection("productgroups")
      .orderBy("name", "asc")
      .onSnapshot((snapshot) => {
        setAllProductGroups(snapshot.docs.map((doc) => doc.data()));
      });
    setAllCountries(countries);
    const iso2Code = getSelectedCountryId();
    fetchCitiesByCountry(iso2Code);
    return () => abortController.abort();
  }, []);

  return (
    <Modal
      show={show}
      onHide={() => setIsEditingCustomer(false)}
      animation={false}
      centered
      className="full__modal"
    >
      <Modal.Header
        closeButton
        style={{ borderBottom: "none", paddingBottom: 0 }}
      >
        <Modal.Title>Edit Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleEdit}>
          {/* Customer Type */}
          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: 600 }}>Customer Type</Form.Label>
            <Form.Group>
              <Form.Check
                inline
                value="Company"
                checked={customerType === "Company"}
                onChange={(e) => {
                  setCustomerType(e.target.value);
                  setEmployeeDetails([
                    {
                      id: uuidv4(),
                      name: "",
                      email: "",
                      phoneNumber1: "",
                      phoneNumber2: "",
                      role: "",
                      dob: "",
                    },
                  ]);
                  setPersonalDetails([]);
                }}
                label="Company"
                type="radio"
              />
              <Form.Check
                inline
                value="Individual"
                checked={customerType === "Individual"}
                onChange={(e) => {
                  setCustomerType(e.target.value);
                  setPersonalDetails([
                    {
                      id: uuidv4(),
                      name: "",
                      email: "",
                      phoneNumber1: "",
                      phoneNumber2: "",
                      role: "primary",
                      dob: "",
                    },
                  ]);
                  setEmployeeDetails([]);
                }}
                label="Individual"
                type="radio"
              />
            </Form.Group>
          </Form.Group>
          {/* Personal Details */}
          {customerType === "Individual" && (
            <div className="form row">
              <ContactPersons
                customerType={customerType}
                details={
                  customerType === "Individual"
                    ? personalDetails
                    : employeeDetails
                }
                setDetails={
                  customerType === "Individual"
                    ? setPersonalDetails
                    : setEmployeeDetails
                }
              />
            </div>
          )}
          {/* Company Details */}
          {customerType === "Company" && (
            <>
              <Form.Group className="mb-3">
                <Form.Label style={{ fontWeight: 600 }}>
                  Company Name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </Form.Group>
              <div className="form row">
                <ContactPersons
                  customerType={customerType}
                  details={
                    customerType === "Individual"
                      ? personalDetails
                      : employeeDetails
                  }
                  setDetails={
                    customerType === "Individual"
                      ? setPersonalDetails
                      : setEmployeeDetails
                  }
                />
              </div>
            </>
          )}
          {/* Address Details */}
          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: 600 }}>Address Details</Form.Label>
            <Form.Control
              type="text"
              placeholder="Physical Address"
              value={address.physical_address}
              onChange={(e) =>
                setAddress({
                  ...address,
                  physical_address: e.target.value,
                })
              }
            />
            <Form.Group className="mt-3 d-flex">
              <Form.Select
                placeholder="Country"
                value={address.country}
                onChange={(e) => {
                  setAddress({ ...address, country: e.target.value });
                  let selectedCountryCode = "";
                  for (let i = 0; i < e.target.children.length; i++) {
                    if (e.target.value === e.target.children[i].value) {
                      selectedCountryCode = e.target.children[i].id;
                      break;
                    }
                  }
                  fetchCitiesByCountry(selectedCountryCode);
                }}
              >
                <option disabled>Country</option>
                {allCountries &&
                  allCountries?.map((country, index) => (
                    <option key={index} id={country?.iso2}>
                      {country?.name}
                    </option>
                  ))}
              </Form.Select>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Form.Select
                placeholder="City"
                value={address.city}
                onChange={(e) =>
                  setAddress({ ...address, city: e.target.value })
                }
              >
                <option disabled>City</option>
                <option>{address.city}</option>
                {allCities &&
                  allCities?.map((city) => (
                    <option key={city?.id}>{city?.name}</option>
                  ))}
              </Form.Select>
            </Form.Group>
          </Form.Group>
          {/* TIN and VRN numbers */}
          <Form.Group className="mb-3">
            <div className="d-flex">
              <Form.Group style={{ flex: 1 }}>
                <Form.Label style={{ fontWeight: 600 }}>TIN Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="TIN Number"
                  value={tin_no}
                  onChange={(e) => {
                    if (e.target.value.length <= 1) {
                      setTin_no(e.target.value);
                    } else {
                      if (e.target.value.length <= 11) {
                        const formatted = e.target.value.replaceAll("-", "");
                        setTin_no(
                          formatted.match(new RegExp(".{1,3}", "g")).join("-")
                        );
                      }
                    }
                  }}
                />
              </Form.Group>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Form.Group style={{ flex: 1 }}>
                <Form.Group style={{ flex: 1 }}>
                  <Form.Label style={{ fontWeight: 600 }}>
                    VRN Number
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="VRN Number"
                      value={vrn_no}
                      onChange={(e) => {
                        if (e.target.value.length <= 11) {
                          let formatted = e.target.value;
                          if (formatted.length === 2) {
                            formatted = formatted + "-";
                          } else if (formatted.length === 9) {
                            formatted = formatted + "-H";
                          }
                          setVrn_no(formatted);
                        }
                      }}
                    />
                    {vrn_no.length > 1 && (
                      <InputGroup.Text
                        onClick={() => setVrn_no("")}
                        style={{ cursor: "pointer" }}
                      >
                        <CgClose style={{ fontSize: 20 }} />
                      </InputGroup.Text>
                    )}
                  </InputGroup>
                </Form.Group>
              </Form.Group>
            </div>
          </Form.Group>
          {/* Interested Product Groups */}
          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: 600 }}>
              Interested Product Groups
            </Form.Label>
            <div className="checkbox__container">
              {allProductGroups?.map((category) => (
                <MyCheckbox
                  {...category}
                  key={category.id}
                  setInterestedProductGroups={setInterestedProductGroups}
                  interestedProductGroups={interestedProductGroups}
                />
              ))}
              <Form.Check
                inline
                checked={allProductsGroupsSelected}
                onChange={() => {
                  setAllProductsGroupsSelected(!allProductsGroupsSelected);
                  if (!allProductsGroupsSelected) {
                    setInterestedProductGroups(
                      allProductGroups.map((category) => category.id)
                    );
                  } else {
                    setInterestedProductGroups([]);
                  }
                }}
                label="Select All"
                type="checkbox"
              />
            </div>
          </Form.Group>
          {/* Others */}
          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: 600 }}>
              Other Product Groups
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Other Product Groups"
              value={otherProductGroups}
              onChange={(e) => setOtherProductGroups(e.target.value)}
            />
          </Form.Group>
          {/* Error message */}
          <Form.Group className="mb-3">
            {errorMsg && (
              <p className="error__msg" style={{ marginBottom: 20 }}>
                <BiErrorCircle />
                &nbsp;{errorMsg}
              </p>
            )}
          </Form.Group>
          {/* Buttons */}
          <Modal.Footer style={{ padding: 0, borderTop: "none" }}>
            <Button
              variant="danger"
              type="button"
              onClick={() => setIsEditingCustomer(false)}
            >
              Cancel
            </Button>
            <Button variant="success" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default memo(EditCustomers);
