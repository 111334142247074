import { memo, useState } from "react";
import { Form } from "react-bootstrap";

const MyCheckbox = ({
  id,
  name,
  interestedProductGroups,
  setInterestedProductGroups,
}) => {
  const [isChecked, setIsChecked] = useState(
    interestedProductGroups?.includes(id)
  );

  const handleChange = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setInterestedProductGroups([...interestedProductGroups, id]);
    } else {
      setInterestedProductGroups(
        interestedProductGroups.filter((c) => c !== id)
      );
    }
  };

  return (
    <Form.Check
      inline
      checked={interestedProductGroups?.includes(id)}
      onChange={handleChange}
      label={name}
      type="checkbox"
    />
  );
};

export default memo(MyCheckbox);
