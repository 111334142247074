import dateFormat from "dateformat";
import logo from "../../assets/marango_pfi_logo.jpg";

const PFIHeader = ({ customer, agent, attention, pfiCall }) => {
  return (
    <div className="pfi_header">
      <div className="logo_container">
        <a href="http://www.marango.co.tz/" target="_blank" rel="noreferrer">
          <img src={logo} alt={logo} width={200} height={100} />
        </a>
        <p>TIN: 105-214-189&nbsp; VRN: 10-019411-H</p>
        <p style={{ fontSize: 11 }}>PLOT 54 CHANGOMBE RD, PO BOX 20370, DAR ES SALAAM TANZANIA</p>

        <div className="buyer_details">
          <h3>Buyers Details</h3>
          <div>
            <span>Name:</span>{" "}
            <p>
              {customer.customerType === "Company"
                ? customer?.companyName
                : customer?.contactPersons[0]?.name}
            </p>
          </div>
          <div>
            <span>Address:</span>
            <p>
              {customer?.address?.physical_address}, {customer?.address?.city},{" "}
              {customer?.address?.country}
            </p>
          </div>
          <div>
            <span>Attention:</span>
            <p>{attention}</p>
          </div>
        </div>
      </div>

      <div className="other_details_container">
        <h3 className="heading">Proforma Invoice</h3>
        <div className="other_details">
          <div>
            <span>PFI REF ML/08/:</span>
            <p>{pfiCall?.proforma_id}</p>
          </div>
          <div>
            <span>Date:</span>
            <p>{dateFormat(new Date(), "mmm dd, yyyy")}</p>
          </div>
          <div>
            <span>Buyers TIN:</span>
            <p>{customer?.TIN}</p>
          </div>
          <div>
            <span>Buyers VRN:</span>
            <p>{customer?.VRN ? customer?.VRN : "N/A"}</p>
          </div>
          <div>
            <span>Customer Type:</span>
            <p>{customer?.customerType}</p>
          </div>
          <div>
            <span>Region:</span>
            <p>{customer?.address?.city}</p>
          </div>
          <div>
            <span>Representative:</span>
            <p>{agent?.name}</p>
          </div>
          <div>
            <span>Telephone:</span>
            <p>{agent?.phonenumber}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PFIHeader;
