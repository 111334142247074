import { useState, useEffect, memo } from "react";
import { db } from "../utils/firebase";
import AddSalesRepresentatives from "../components/SalesRepresentatives/AddSalesRepresentatives";
import SalesRepresentativesTable from "../components/SalesRepresentatives/SalesRepresentativesTable";
import Loader from "../components/Loader/Loader";
import { Button, Container } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";
import "../styles/SalesRepresentatives.css";

const SalesRepresentatives = () => {
  const [salesRepresentatives, setSalesRepresentatives] = useState([]);
  const [isAddingSalesRepresentatives, setIsAddingSalesRepresentatives] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let abortController = new AbortController();
    db.collection("users")
      .where("role", "==", "sales representative")
      .orderBy("name", "asc")
      .onSnapshot((snapshot) => {
        setSalesRepresentatives(snapshot.docs.map((doc) => doc.data()));
        setIsLoading(false);
      });
    return () => abortController.abort();
  }, []);

  return (
    <Container>
      <div className="salesrepresentative__title">
        <h3 className="title">Sales Representatives</h3>
        <Button
          onClick={() =>
            setIsAddingSalesRepresentatives(!isAddingSalesRepresentatives)
          }
          variant="success"
        >
          <p style={{ marginBottom: 0, display: "flex", alignItems: "center" }}>
            <BsPlusLg />
            &nbsp;&nbsp;Add Sales Representative
          </p>
        </Button>
      </div>
      {isAddingSalesRepresentatives && (
        <AddSalesRepresentatives
          isAddingSalesRepresentatives={isAddingSalesRepresentatives}
          setIsAddingSalesRepresentatives={setIsAddingSalesRepresentatives}
        />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <SalesRepresentativesTable
          salesRepresentatives={salesRepresentatives}
        />
      )}
    </Container>
  );
};

export default memo(SalesRepresentatives);
