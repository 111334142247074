import { memo, useState } from "react";
import { db, timestamp } from "../../utils/firebase";
import CryptoJS from "crypto-js";
import { Button, Form, FormControl, InputGroup, Modal } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { BiErrorCircle } from "react-icons/bi";

const AddSalesRepresentatives = ({
  isAddingSalesRepresentatives,
  setIsAddingSalesRepresentatives,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [phonenumber2, setPhoneNumber2] = useState("");
  const [phonenumber3, setPhoneNumber3] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [dob, setDob] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleAdd = async (e) => {
    e.preventDefault();
    if (!name) {
      setErrorMsg("Name is required");
      return;
    } else if (!username) {
      setErrorMsg("Username is required");
      return;
    } else if (!email) {
      setErrorMsg("Email is required");
      return;
    } else if (!dob) {
      setErrorMsg("DOB is required");
      return;
    } else if (!password) {
      setErrorMsg("Password is required");
      return;
    } else if (!phonenumber) {
      setErrorMsg("First phone number is required");
      return;
    } else if (password.length < 6) {
      setErrorMsg("Minimum password length must be 6");
      return;
    } else if (isNaN(phonenumber)) {
      setErrorMsg("Please enter a valid contact number");
      return;
    } else {
      setErrorMsg("");
      const id = uuid();
      db.collection("users")
        .doc(id)
        .set({
          username: username.toLowerCase(),
          email,
          id,
          name,
          phonenumber: "+255 " + phonenumber,
          phonenumber2: phonenumber2 ? "+255 " + phonenumber2 : "",
          phonenumber3: phonenumber3 ? "+255 " + phonenumber3 : "",
          password: CryptoJS.SHA256(password).toString(CryptoJS.enc.Base64),
          role: "sales representative",
          dob,
          createdAt: timestamp,
          updatedAt: timestamp,
        });
      setIsAddingSalesRepresentatives(false);
    }
    setName("");
    setEmail("");
    setPassword("");
    setPhoneNumber("");
    setPhoneNumber2("");
    setPhoneNumber3("");
    setUsername("");
  };

  return (
    <>
      <Modal
        show={isAddingSalesRepresentatives}
        onHide={() => setIsAddingSalesRepresentatives(false)}
        animation={false}
        centered
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: "none", paddingBottom: 0 }}
        >
          <Modal.Title>Add Sales Representative</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAdd}>
            {/* Name */}
            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: 600 }}>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>

            {/* Username  */}
            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: 600 }}>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>

            {/* Email */}
            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: 600 }}>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            {/* dob */}
            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: 500 }}>Date of Birth</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                placeholder="Date of Birth"
              />
            </Form.Group>

            {/* Password */}
            <Form.Group className="mb-2">
              <Form.Label style={{ fontWeight: 600 }}>Password</Form.Label>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Form.Group className="mt-1 d-flex align-content-center">
                <Form.Label style={{ fontWeight: 600 }}>
                  Show Password
                </Form.Label>
                &nbsp;&nbsp;
                <Form.Check
                  type="switch"
                  checked={showPassword}
                  onChange={() => setShowPassword(!showPassword)}
                />
              </Form.Group>
            </Form.Group>

            {/* Phone Number */}
            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: 600 }}>Phone Number</Form.Label>
              <InputGroup>
                <InputGroup.Text>+255</InputGroup.Text>
                <FormControl
                  type="text"
                  placeholder="Phone Number"
                  value={phonenumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </InputGroup>
            </Form.Group>

            {/* Phone Number 2*/}
            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: 600 }}>
                Second Phone Number (optional)
              </Form.Label>
              <InputGroup>
                <InputGroup.Text>+255</InputGroup.Text>
                <FormControl
                  type="text"
                  placeholder="Second Phone Number"
                  value={phonenumber2}
                  onChange={(e) => setPhoneNumber2(e.target.value)}
                />
              </InputGroup>
            </Form.Group>

            {/* Phone Number 3 */}
            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: 600 }}>
                Third Phone Number (optional)
              </Form.Label>
              <InputGroup>
                <InputGroup.Text>+255</InputGroup.Text>
                <FormControl
                  type="text"
                  placeholder="Third Phone Number"
                  value={phonenumber3}
                  onChange={(e) => setPhoneNumber3(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
            
            {/* Error Message */}
            <Form.Group>
              {errorMsg && (
                <p className="error__msg" style={{ marginBottom: 20 }}>
                  <BiErrorCircle />
                  &nbsp;{errorMsg}
                </p>
              )}
            </Form.Group>

            {/* Submit */}
            <Modal.Footer style={{ padding: 0, borderTop: "none" }}>
              <Button
                variant="danger"
                type="button"
                onClick={() => setIsAddingSalesRepresentatives(false)}
              >
                Cancel
              </Button>
              <Button variant="success" type="submit">
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(AddSalesRepresentatives);
