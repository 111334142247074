import { memo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button, Form } from "react-bootstrap";
import { BsTrashFill } from "react-icons/bs";
import { BiErrorCircle } from "react-icons/bi";

const ProductsForm = ({ type, products, setProducts }) => {
  const [errorMsg, setErrorMsg] = useState("");

  const handleChange = (i, e) => {
    let newFormValues = [...products];
    const { name, value } = e.target;
    if (
      (name === "price" || name === "price" || name === "quantity") &&
      value.length > 2
    ) {
      if (isNaN(value.replaceAll(",", ""))) {
        return;
      }
      newFormValues[i][name] = Number(
        value.replaceAll(",", "")
      ).toLocaleString();
    } else {
      newFormValues[i][name] = value;
    }
    setProducts(newFormValues);
  };

  const addFormFields = () => {
    let n = products.length - 1;
    setErrorMsg("");
    if (type === "No Order") {
      if (!products[n].name || !products[n].price || !products[n].quantity) {
        setErrorMsg("Product Name, Price and Quantity is required.");
        return;
      }
    } else if (type === "Order Confirmed" || type === "Lead") {
      if (!products[n].name || !products[n].price || !products[n].quantity) {
        setErrorMsg("Product Name, Price and Quantity is required.");
        return;
      }
    }
    if (type === "Lead") {
      setProducts([
        ...products,
        {
          id: uuidv4(),
          name: "",
          price: "",
          quantity: "",
        },
      ]);
    } else {
      setProducts([
        ...products,
        {
          id: uuidv4(),
          name: "",
          price: "",
          quantity: "",
          remarks: "",
        },
      ]);
    }
  };

  const removeFormFields = (i) => {
    let newFormValues = [...products];
    newFormValues.splice(i, 1);
    setProducts(newFormValues);
  };

  return (
    <div>
      {products &&
        products?.map((product, index) => (
          <Form.Group className="d-flex mb-2" key={index}>
            <Form.Control
              type="text"
              placeholder="Name"
              name="name"
              value={product.name || ""}
              onChange={(e) => handleChange(index, e)}
            />
            &nbsp;&nbsp;&nbsp;
            {type === "Lead" ? (
              <Form.Control
                type="text"
                placeholder="Price"
                name="price"
                value={product.price || ""}
                onChange={(e) => handleChange(index, e)}
              />
            ) : (
              <Form.Control
                type="text"
                placeholder="Price Quoted"
                name="priceQuoted"
                value={product.price || ""}
                onChange={(e) => handleChange(index, e)}
              />
            )}
            &nbsp;&nbsp;&nbsp;
            <Form.Control
              type="text"
              placeholder="Quantity"
              name="quantity"
              value={product.quantity || ""}
              onChange={(e) => handleChange(index, e)}
            />
            {type === "No Order" && (
              <>
                &nbsp;&nbsp;&nbsp;
                <Form.Control
                  type="text"
                  placeholder="Remarks"
                  name="remarks"
                  value={product.remarks || ""}
                  onChange={(e) => handleChange(index, e)}
                />
              </>
            )}
            &nbsp;&nbsp;&nbsp;
            <Button
              type="button"
              variant="danger"
              onClick={() => removeFormFields(index)}
              disabled={products.length === 1}
            >
              <BsTrashFill />
            </Button>
          </Form.Group>
        ))}

      {/* Error Message */}
      {errorMsg && (
        <p className="error__msg" style={{ marginBottom: 20 }}>
          <BiErrorCircle />
          &nbsp;{errorMsg}
        </p>
      )}

      <div className="d-flex mb-3 mt-2 justify-content-end">
        <Button
          type="button"
          variant="success"
          onClick={addFormFields}
          className="btn"
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default memo(ProductsForm);
