import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import errorImage from "../assets/404.png";
import "../styles/ErrorPage.css";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className="error__page__container">
      <img src={errorImage} alt="404 page not found" />
      <Button variant="success" onClick={() => navigate("/")}>
        Go to Home
      </Button>
    </div>
  );
};

export default memo(ErrorPage);
