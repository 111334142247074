/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState, memo } from "react";
import { AuthContext } from "../../context/authContext";
import { db, timestamp } from "../../utils/firebase";
import { v4 as uuidv4 } from "uuid";
import { Modal, Button, Form, Badge } from "react-bootstrap";
import dateFormat from "dateformat";
import ProductsForm from "./ProductForms";
import MyCheckbox from "../MyCheckbox/MyCheckbox";
import CallDetailsModal from "./CallDetailsModal";
import { BiErrorCircle } from "react-icons/bi";
import "../../styles/Calls.css";

const ALL_CATEGORIES = {};

const FeedbackModal = ({ show, setIsCall, customer, isNewCall, call }) => {
  const [lastCallDetails, setLastCallDetails] = useState(call || null);
  const [feedbackStatus, setFeedbackStatus] = useState(call?.status || "Lead");
  const [discussion, setDiscussion] = useState(call?.discussion || "");
  const [requiredProducts, setRequiredProducts] = useState(
    call?.productsRequired || [
      {
        id: uuidv4(),
        name: "",
        price: "",
        quantity: "",
      },
    ]
  );
  const [noOrderProducts, setNoOrderProducts] = useState(
    call?.noOrderProducts || [
      {
        id: uuidv4(),
        name: "",
        price: "",
        quantity: "",
        remarks: "",
      },
    ]
  );
  const [isRequirement, setIsRequirement] = useState(
    call?.isRequirement || false
  );
  const [isCallback, setIsCallback] = useState(call?.isCallback || true);
  const [callbackDate, setCallbackDate] = useState(
    call?.callback_date
      ? new Date(
          call?.callback_date?.seconds * 1000 -
            new Date(call?.callback_date?.seconds * 1000).getTimezoneOffset(
              Date.now()
            ) *
              60000
        )
          .toISOString()
          .substring(0, 16)
      : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substring(0, 16)
  );
  const [notAvailableStatus, setNotAvailableStatus] = useState(
    call?.notAvailableStatus || "Switched Off/Unreachable"
  );
  const [relatedProductGroups, setRelatedProductGroups] = useState(
    call?.relatedProductGroups || []
  );
  const [errorMsg, setErrorMsg] = useState("");
  const [showCallDetails, setShowCallDetails] = useState(
    call?.showCallDetails || false
  );
  const [statusColor, setStatusColor] = useState("");
  const [allInterestedProductGroups, setAllInterestedProductGroups] = useState(
    {}
  );
  const { currentUser } = useContext(AuthContext);

  const handleFeedback = (e) => {
    e.preventDefault();
    setErrorMsg("");
    try {
      if (!discussion) {
        setErrorMsg("Discussion cannot be empty.");
        return;
      } else if (
        feedbackStatus === "No Order" &&
        isRequirement &&
        (!noOrderProducts[noOrderProducts.length - 1].name ||
          !noOrderProducts[noOrderProducts.length - 1].price ||
          !noOrderProducts[noOrderProducts.length - 1].quantity)
      ) {
        setErrorMsg("Please fill the required product details");
        return;
      } else if (
        (feedbackStatus === "Lead" || feedbackStatus === "Order Confirmed") &&
        (!requiredProducts[requiredProducts.length - 1].name ||
          !requiredProducts[requiredProducts.length - 1].price ||
          !requiredProducts[requiredProducts.length - 1].quantity)
      ) {
        setErrorMsg("Please fill the required product details");
        return;
      }
      let newCallId = uuidv4();

      if (feedbackStatus === "Order Confirmed") {
        // If Order is confirmed then save that call in order collection
        const tempId = uuidv4();
        db.collection("orders")
          .doc(lastCallDetails?.id || tempId)
          .set({
            id: lastCallDetails?.id || tempId,
            agent_id: currentUser?.id,
            customer_id: customer?.id,
            discussion,
            productsRequired:
              requiredProducts[requiredProducts.length - 1] === ""
                ? requiredProducts.slice(0, requiredProducts.length - 1)
                : requiredProducts,
            relatedProductGroups,
            createdAt: timestamp,
            updatedAt: timestamp,
          });
        if (lastCallDetails && !isNewCall) {
          // If order is confirmed and we have a previous entry in calls table then only update the status of that call
          db.collection("calls")
            .doc(lastCallDetails?.id)
            .update({
              status: "Order Confirmed",
              isCallback: false,
              callback_date: "",
              updatedAt: timestamp,
              productsRequired:
                requiredProducts[requiredProducts.length - 1] === ""
                  ? requiredProducts.slice(0, requiredProducts.length - 1)
                  : requiredProducts,
              discussion,
              agent_id: currentUser?.id,
              notAvailableStatus:
                feedbackStatus === "Not Available" ? notAvailableStatus : "",
            });
        }
      } else {
        if (lastCallDetails && !isNewCall) {
          // If we have called the customer previously then only update the particular document in calls collection
          db.collection("calls")
            .doc(lastCallDetails?.id)
            .update({
              agent_id: currentUser?.id,
              status: feedbackStatus,
              discussion,
              isCallback,
              isRequirement,
              callback_date: isCallback ? new Date(callbackDate) : "",
              productsRequired:
                requiredProducts[requiredProducts.length - 1] === ""
                  ? requiredProducts.slice(0, requiredProducts.length - 1)
                  : requiredProducts,
              noOrderProducts:
                noOrderProducts[noOrderProducts.length - 1] === ""
                  ? noOrderProducts.slice(0, noOrderProducts.length - 1)
                  : noOrderProducts,
              relatedProductGroups,
              updatedAt: timestamp,
              notAvailableStatus:
                feedbackStatus === "Not Available" ? notAvailableStatus : "",
            });
        } else {
          // If we are calling customer for the very first time then make a new entry in the calls collection
          db.collection("calls")
            .doc(newCallId)
            .set({
              id: newCallId,
              agent_id: currentUser?.id,
              customer_id: customer?.id,
              status: feedbackStatus,
              discussion,
              isCallback,
              isRequirement,
              callback_date: isCallback ? new Date(callbackDate) : "",
              productsRequired:
                requiredProducts[requiredProducts.length - 1] === ""
                  ? requiredProducts.slice(0, requiredProducts.length - 1)
                  : requiredProducts,
              noOrderProducts:
                noOrderProducts[noOrderProducts.length - 1] === ""
                  ? noOrderProducts.slice(0, noOrderProducts.length - 1)
                  : noOrderProducts,
              relatedProductGroups,
              createdAt: timestamp,
              updatedAt: timestamp,
              notAvailableStatus:
                feedbackStatus === "Not Available" ? notAvailableStatus : "",
            });
        }
      }

      // For each call save the current calls details in callhistory collection
      const historyId = uuidv4();
      db.collection("callhistory")
        .doc(historyId)
        .set({
          id: historyId,
          call_id: lastCallDetails?.id || newCallId,
          agent_id: currentUser?.id,
          customer_id: customer?.id,
          status: feedbackStatus,
          discussion,
          isCallback,
          isRequirement,
          callback_date: isCallback ? new Date(callbackDate) : "",
          productsRequired:
            requiredProducts[requiredProducts.length - 1] === ""
              ? requiredProducts.slice(0, requiredProducts.length - 1)
              : requiredProducts,
          noOrderProducts:
            noOrderProducts[noOrderProducts.length - 1] === ""
              ? noOrderProducts.slice(0, noOrderProducts.length - 1)
              : noOrderProducts,
          relatedProductGroups,
          createdAt: timestamp,
          notAvailableStatus:
            feedbackStatus === "Not Available" ? notAvailableStatus : "",
        });
      setIsCall(false);
    } catch (err) {
      if (err.name === "RangeError") {
        setErrorMsg("Invalid time value");
      }
      console.log(err.message);
    }
  };

  const fetchAllProductGroups = () => {
    db.collection("productgroups")
      .orderBy("name", "asc")
      .onSnapshot((snapshot) => {
        snapshot.docs.forEach((doc) => {
          const { id, name } = doc.data();
          ALL_CATEGORIES[id] = name;
        });
        setAllInterestedProductGroups(ALL_CATEGORIES);
      });
  };

  useEffect(() => {
    const abortController = new AbortController();
    if (call) {
      if (lastCallDetails?.status === "Lead") {
        setStatusColor("success");
      } else if (lastCallDetails?.status === "No Order") {
        setStatusColor("danger");
      } else if (lastCallDetails?.status === "Not Available") {
        setStatusColor("warning");
      } else if (lastCallDetails?.status === "Order Confirmed") {
        setStatusColor("success");
      }
    } else if (!call && !isNewCall) {
      db.collection("calls")
        .where("customer_id", "==", customer?.id)
        .where("agent_id", "==", currentUser?.id)
        .onSnapshot((snapshot) => {
          const lastCall =
            snapshot.docs.length > 0 ? snapshot.docs[0].data() : null;
          setLastCallDetails(lastCall);
          setFeedbackStatus(lastCall ? lastCall.status : "Lead");
          setDiscussion(lastCall ? lastCall.discussion : "");
          // setIsRequirement(lastCall ? lastCall.isRequirement : false);
          setRequiredProducts(
            lastCall?.productsRequired?.length > 0
              ? lastCall.productsRequired
              : [
                  {
                    id: uuidv4(),
                    name: "",
                    price: "",
                    quantity: "",
                  },
                ]
          );
          if (lastCall?.status === "Lead") {
            setNoOrderProducts(lastCall.productsRequired);
            setIsRequirement(true);
          } else {
            setNoOrderProducts(
              lastCall?.noOrderProducts.length > 0
                ? lastCall.noOrderProducts
                : [
                    {
                      id: uuidv4(),
                      name: "",
                      price: "",
                      quantity: "",
                      remarks: "",
                    },
                  ]
            );
            setIsRequirement(false);
          }
          // isCallback
          setIsCallback(lastCall ? lastCall.isCallback : true);
          // callback date
          if (lastCall?.isCallback) {
            setCallbackDate(
              lastCall
                ? new Date(
                    lastCall?.callback_date?.seconds * 1000 -
                      new Date(
                        lastCall?.callback_date?.seconds * 1000
                      ).getTimezoneOffset(Date.now()) *
                        60000
                  )
                    .toISOString()
                    .substring(0, 16)
                : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substring(0, 16)
            );
          }
          // related product group
          setRelatedProductGroups(
            lastCall ? lastCall.relatedProductGroups : []
          );
          // setting color for background color of status batch
          if (lastCall?.status === "Lead") {
            setStatusColor("success");
          } else if (lastCall?.status === "No Order") {
            setStatusColor("danger");
          } else if (lastCall?.status === "Not Available") {
            setStatusColor("warning");
          } else if (lastCall?.status === "Order Confirmed") {
            setStatusColor("success");
          }
        });
    } else if (!call && isNewCall) {
      db.collection("calls")
        .where("customer_id", "==", customer?.id)
        .where("agent_id", "==", currentUser?.id)
        .onSnapshot((snapshot) => {
          const lastCall =
            snapshot.docs.length > 0 ? snapshot.docs[0].data() : null;
          setLastCallDetails(lastCall);
          if (lastCall?.status === "Lead") {
            setStatusColor("success");
          } else if (lastCall?.status === "No Order") {
            setStatusColor("danger");
          } else if (lastCall?.status === "Not Available") {
            setStatusColor("warning");
          } else if (lastCall?.status === "Order Confirmed") {
            setStatusColor("success");
          }
        });
    }
    fetchAllProductGroups();

    return () => abortController.abort();
  }, []);

  return (
    <>
      {showCallDetails && (
        <CallDetailsModal
          show={showCallDetails}
          customer={customer}
          setShowCallDetails={setShowCallDetails}
          lastCallDetails={lastCallDetails}
        />
      )}
      <Modal
        show={show}
        onHide={() => setIsCall(false)}
        animation={false}
        backdrop="static"
        centered
        className="full__modal"
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: "none", paddingBottom: 0 }}
        >
          <Modal.Title>
            Calling{" "}
            {customer?.customerType === "Company"
              ? customer?.companyName ?? "(deleted)"
              : customer?.contactPersons[0]?.name ?? "(deleted)"}
            &nbsp;
            {!isNewCall && <Badge bg={statusColor}>{feedbackStatus}</Badge>}
            <br />
            <span style={{ fontSize: 16, fontWeight: 400 }}>
              {lastCallDetails
                ? "last called on " +
                  dateFormat(
                    new Date(lastCallDetails?.updatedAt?.seconds * 1000),
                    "ddd, dd mmm  yyyy, hh:MM TT"
                  )
                : "Calling for the first time"}
            </span>
            &nbsp;&nbsp;
            {lastCallDetails && (
              <Button
                onClick={() => setShowCallDetails(true)}
                variant="success"
                style={{ fontSize: 16, fontWeight: 400 }}
              >
                View Details
              </Button>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFeedback}>
            {/* Feedback Status */}
            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: 600 }}>
                Feedback Status
              </Form.Label>
              <Form.Group>
                <Form.Check
                  inline
                  value="Lead"
                  checked={feedbackStatus === "Lead"}
                  onChange={(e) => {
                    setFeedbackStatus(e.target.value);
                    setDiscussion("");
                  }}
                  label="Lead"
                  type="radio"
                />
                <Form.Check
                  inline
                  value="Order Confirmed"
                  checked={feedbackStatus === "Order Confirmed"}
                  onChange={(e) => {
                    setFeedbackStatus(e.target.value);
                    setDiscussion("");
                  }}
                  label="Order Confirmed"
                  type="radio"
                />
                <Form.Check
                  inline
                  value="No Order"
                  checked={feedbackStatus === "No Order"}
                  onChange={(e) => {
                    setFeedbackStatus(e.target.value);
                    setDiscussion("");
                  }}
                  label="No Order"
                  type="radio"
                />
                <Form.Check
                  inline
                  value="Not Available"
                  checked={feedbackStatus === "Not Available"}
                  onChange={(e) => {
                    setFeedbackStatus(e.target.value);
                    setDiscussion("Switched Off/Unreachable");
                  }}
                  label="Not Available"
                  type="radio"
                />
              </Form.Group>
            </Form.Group>

            {/* If not available (Switched Off/Unreachable, Not Answered, Other(text field)) */}
            {feedbackStatus === "Not Available" && (
              <Form.Group className="mb-3">
                <Form.Label style={{ fontWeight: 600 }}>
                  Not Available Reason
                </Form.Label>
                <Form.Group>
                  <Form.Check
                    inline
                    value="Switched Off/Unreachable"
                    checked={notAvailableStatus === "Switched Off/Unreachable"}
                    onChange={(e) => {
                      setNotAvailableStatus(e.target.value);
                      setDiscussion(e.target.value);
                    }}
                    label="Switched Off/Unreachable"
                    type="radio"
                  />
                  <Form.Check
                    inline
                    value="Not Answered"
                    checked={notAvailableStatus === "Not Answered"}
                    onChange={(e) => {
                      setNotAvailableStatus(e.target.value);
                      setDiscussion(e.target.value);
                    }}
                    label="Not Answered"
                    type="radio"
                  />
                  <Form.Check
                    inline
                    value="Other"
                    checked={notAvailableStatus === "Other"}
                    onChange={(e) => {
                      setNotAvailableStatus(e.target.value);
                      setDiscussion("");
                    }}
                    label="Other"
                    type="radio"
                  />
                </Form.Group>
              </Form.Group>
            )}

            {/* If Lead */}
            {feedbackStatus === "Lead" && (
              <Form.Group className="mb-3">
                <Form.Label style={{ fontWeight: 600 }}>
                  Required Products
                </Form.Label>
                <ProductsForm
                  type="Lead"
                  products={requiredProducts}
                  setProducts={setRequiredProducts}
                />
              </Form.Group>
            )}

            {/* If Order Confirmed */}
            {feedbackStatus === "Order Confirmed" && (
              <Form.Group className="form__row">
                <Form.Label style={{ fontWeight: 600 }}>
                  Required Products
                </Form.Label>
                <ProductsForm
                  type="Lead"
                  products={requiredProducts}
                  setProducts={setRequiredProducts}
                />
              </Form.Group>
            )}

            {/* If No Order */}
            {feedbackStatus === "No Order" && (
              <Form.Group className="mb-3">
                <Form.Group className="mt-1 d-flex align-content-center">
                  <Form.Label style={{ fontWeight: 600 }}>
                    Was There any Requirement?
                  </Form.Label>
                  &nbsp;&nbsp;
                  <Form.Check
                    type="switch"
                    checked={isRequirement}
                    onChange={() => {
                      setIsRequirement(!isRequirement);
                      setNoOrderProducts(requiredProducts);
                    }}
                  />
                </Form.Group>
              </Form.Group>
            )}

            {/* If No Order & Requirement */}
            {feedbackStatus === "No Order" && isRequirement && (
              <Form.Group className="form__row">
                <Form.Label style={{ fontWeight: 600 }}>
                  Required Products
                </Form.Label>
                <ProductsForm
                  type="No Order"
                  products={noOrderProducts}
                  setProducts={setNoOrderProducts}
                />
              </Form.Group>
            )}

            {/* Discussion */}
            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: 600 }}>Discussion</Form.Label>
              <Form.Control
                as="textarea"
                value={discussion}
                placeholder="Discussion"
                onChange={(e) => setDiscussion(e.target.value)}
              />
            </Form.Group>

            {/* Related ProductGroup */}
            {feedbackStatus !== "Not Available" && (
              <Form.Group className="mb-3">
                <Form.Label style={{ fontWeight: 600 }}>
                  Related Product Groups
                </Form.Label>
                <div className="checkbox__container">
                  {customer?.interestedProductGroups?.map((category, index) => (
                    <MyCheckbox
                      id={category}
                      name={allInterestedProductGroups[category]}
                      key={index}
                      setInterestedProductGroups={setRelatedProductGroups}
                      interestedProductGroups={relatedProductGroups}
                    />
                  ))}
                </div>
              </Form.Group>
            )}

            {/* IsCallback */}
            {feedbackStatus !== "Order Confirmed" && (
              <Form.Group className="mb-3">
                <Form.Group className="mt-1 d-flex align-content-center">
                  <Form.Label style={{ fontWeight: 600 }}>
                    Schedule Callback
                  </Form.Label>
                  &nbsp;&nbsp;
                  <Form.Check
                    type="switch"
                    checked={isCallback}
                    onChange={() => {
                      if (
                        feedbackStatus === "Lead" ||
                        feedbackStatus === "Not Available"
                      )
                        setIsCallback(true);
                      else setIsCallback(!isCallback);
                    }}
                  />
                </Form.Group>
              </Form.Group>
            )}

            {/* Schedule Callback */}
            {isCallback && feedbackStatus !== "Order Confirmed" && (
              <Form.Group className="mb-3">
                <Form.Control
                  type="datetime-local"
                  value={callbackDate}
                  onChange={(e) => setCallbackDate(e.target.value)}
                  style={{ width: 300 }}
                />
              </Form.Group>
            )}

            {/* Order Failed */}
            <Form.Group className="mb-3">
              {feedbackStatus === "No Order" &&
                !isCallback &&
                isRequirement && (
                  <h2 className="text-danger">ORDER FAILED! ❌</h2>
                )}
            </Form.Group>

            {/* Error Message */}
            <Form.Group className="mb-3">
              {errorMsg && (
                <p className="error__msg" style={{ marginBottom: 20 }}>
                  <BiErrorCircle />
                  &nbsp;{errorMsg}
                </p>
              )}
            </Form.Group>

            <Modal.Footer style={{ padding: 0, borderTop: "none" }}>
              <Button
                variant="danger"
                onClick={() => setIsCall(false)}
                type="button"
              >
                Cancel
              </Button>
              <Button variant="success" type="submit">
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(FeedbackModal);
