/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useContext, useEffect, useState } from "react";
import { db } from "../../utils/firebase";
import { AuthContext } from "../../context/authContext";
import dateFormat from "dateformat";
import FeedbackModal from "../Calls/FeedbackModal";
import CallDetailsModal from "../Calls/CallDetailsModal";
import { Button } from "react-bootstrap";
import { IoCall } from "react-icons/io5";
import { IoMdDownload } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const ScheduledCallsRow = ({ call, index, searchTerm }) => {
  const [currentCustomer, setCurrentCustomer] = useState({});
  const [currentAgent, setCurrentAgent] = useState({});
  const [isCall, setIsCall] = useState(false);
  const [showCallDetails, setShowCallDetails] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  function generateProformaId() {
    db.collection("reference")
      .doc("fJ7BW37pjEUJsQvE7S4x")
      .get()
      .then((res) => {
        db.collection("calls")
          .doc(call.id)
          .update({
            proforma_id: res.data().number,
          })
          .then(() => {
            db.collection("reference")
              .doc("fJ7BW37pjEUJsQvE7S4x")
              .update({ number: res.data().number + 1 })
              .then(() => {
                alert("Proforma id generated");
              });
          });
      })
      .catch((err) => console.log(err.message));
  }

  useEffect(() => {
    const abortController = new AbortController();
    db.collection("customers")
      .where("id", "==", call.customer_id)
      .onSnapshot((snapshot) => {
        setCurrentCustomer(snapshot.docs.map((doc) => doc.data()));
      });
    return abortController.abort();
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    if (currentUser.role === "admin" || currentUser.role === "super admin") {
      db.collection("users")
        .where("id", "==", call.agent_id)
        .onSnapshot((snapshot) => {
          setCurrentAgent(snapshot.docs.map((doc) => doc.data()));
        });
    }
    return abortController.abort();
  }, []);

  return (
    <>
      {isCall && (
        <FeedbackModal
          show={isCall}
          setIsCall={setIsCall}
          customer={currentCustomer[0]}
          isNewCall={false}
          call={call}
        />
      )}
      {showCallDetails && (
        <CallDetailsModal
          show={showCallDetails}
          setShowCallDetails={setShowCallDetails}
          customer={currentCustomer[0]}
          lastCallDetails={call}
        />
      )}

      {(currentAgent[0]?.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
        currentCustomer[0]?.companyName
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        currentCustomer[0]?.contactPersons[0]?.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) && (
        <tr>
          <td>{index}.</td>
          <td>
            {currentCustomer[0]?.customerType === "Company"
              ? currentCustomer[0]?.companyName ?? "(deleted)"
              : currentCustomer[0]?.contactPersons[0]?.name ?? "(deleted)"}
            &nbsp;({currentCustomer[0]?.customerType})
          </td>
          <td>
            {currentCustomer[0]?.contactPersons[0]?.phoneNumber1 ?? "(deleted)"}
          </td>
          <td>{call?.status}</td>
          <td>
            {dateFormat(
              new Date(call?.callback_date?.seconds * 1000),
              "ddd, dd mmm  yyyy, hh:MM TT"
            )}
          </td>
          <td>{call?.discussion}</td>

          <td>
            {currentUser?.role === "sales representative" &&
              (currentCustomer.length === 1 ? (
                <div className="d-flex">
                  <button
                    onClick={() => setIsCall(true)}
                    className="action__btn"
                  >
                    <IoCall />
                  </button>
                  &nbsp;
                  {call?.status === "Lead" ? (
                    <button
                      onClick={() => {
                        if (call?.proforma_id) {
                          window.open(`/pfi/${call?.id}`, "_blank");
                        } else {
                          alert("Please generate proforma id");
                        }
                      }}
                      className="action__btn"
                    >
                      <IoMdDownload fontSize={20} />
                    </button>
                  ) : (
                    <span>N/A</span>
                  )}
                </div>
              ) : (
                "(deleted)"
              ))}

            {currentUser?.role !== "sales representative" &&
              (currentCustomer.length === 1 ? (
                <div className="d-flex">
                  {call?.status === "Lead" ? (
                    <button
                      onClick={() => {
                        if (call?.proforma_id) {
                          window.open(`/pfi/${call?.id}`, "_blank");
                        } else {
                          alert("Please generate proforma id");
                        }
                      }}
                      className="action__btn"
                    >
                      <IoMdDownload fontSize={20} />
                    </button>
                  ) : (
                    <span>N/A</span>
                  )}
                </div>
              ) : (
                "(deleted)"
              ))}
          </td>

          {(currentUser?.role === "admin" ||
            currentUser?.role === "super admin") && (
            <td>{currentAgent[0]?.name ?? "(deleted)"}</td>
          )}

          <td>
            <Button variant="success" onClick={() => setShowCallDetails(true)}>
              View
            </Button>
          </td>
          <td>
            <Button
              variant="success"
              onClick={() => navigate(`/call/${call?.id}`)}
            >
              View
            </Button>
          </td>

          <td>
            {call?.status === "Lead" ? (
              call?.proforma_id ? (
                "Generated"
              ) : (
                <Button variant="success" onClick={generateProformaId}>
                  Generate
                </Button>
              )
            ) : (
              "N/A"
            )}
          </td>
        </tr>
      )}
    </>
  );
};

export default memo(ScheduledCallsRow);
