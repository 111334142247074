import { memo } from "react";
import OrderRow from "./OrderRow";
import { Table } from "react-bootstrap";

const orderTable = ({ orders, searchTerm }) => {
  return (
    <Table responsive striped bordered>
      <thead>
        <tr>
          <th>Sr No.</th>
          <th>Customer Name</th>
          <th>Phone Number</th>
          <th>Time</th>
          <th>Sales Representative</th>
          <th>Details</th>
        </tr>
      </thead>
      <tbody>
        {orders?.map((call, index) => (
          <OrderRow
            call={call}
            key={call.id}
            index={index + 1}
            searchTerm={searchTerm}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default memo(orderTable);
