import { memo, useState } from "react";
import { db, timestamp } from "../../utils/firebase";
import { Button, Form, Modal } from "react-bootstrap";
import { BiErrorCircle } from "react-icons/bi";

const EditRoles = ({ show, setIsEditingRole, role }) => {
  const [name, setName] = useState(role?.name);
  const [errorMsg, setErrorMsg] = useState("");

  const handleEdit = async (e) => {
    e.preventDefault();
    if (!name) {
      setErrorMsg("Roles cannot be empty!");
      return;
    } else {
      setErrorMsg("");
      db.collection("roles").doc(role.id).update({
        name,
        updatedAt: timestamp,
      });
      setIsEditingRole(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => setIsEditingRole(false)}
      animation={false}
      centered
    >
      <Modal.Header
        closeButton
        style={{ borderBottom: "none", paddingBottom: 0 }}
      >
        <Modal.Title>Edit Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleEdit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label style={{ fontWeight: 600 }}>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            {errorMsg && (
              <p className="error__msg" style={{ marginBottom: 20 }}>
                <BiErrorCircle />
                &nbsp;{errorMsg}
              </p>
            )}
          </Form.Group>
          <Modal.Footer style={{ padding: 0, borderTop: "none" }}>
            <Form.Group>
              <Button
                variant="danger"
                type="button"
                onClick={() => setIsEditingRole(false)}
              >
                Cancel
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button variant="success" type="submit">
                Save Changes
              </Button>
            </Form.Group>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default memo(EditRoles);
