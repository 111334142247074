/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useState } from "react";
import { db } from "../../utils/firebase";
import dateFormat from "dateformat";
import CallDetailsModal from "../Calls/CallDetailsModal";
import { Button } from "react-bootstrap";

const SingleCallHistoryRow = ({ call, index }) => {
  const [currentCustomer, setCurrentCustomer] = useState({});
  const [currentAgent, setCurrentAgent] = useState({});
  const [showCallDetails, setShowCallDetails] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    db.collection("customers")
      .where("id", "==", call.customer_id)
      .onSnapshot((snapshot) => {
        setCurrentCustomer(snapshot.docs.map((doc) => doc.data()));
      });
    return abortController.abort();
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    db.collection("users")
      .where("id", "==", call.agent_id)
      .onSnapshot((snapshot) => {
        setCurrentAgent(snapshot.docs.map((doc) => doc.data()));
      });
    return abortController.abort();
  }, []);

  return (
    <>
      {showCallDetails && (
        <CallDetailsModal
          show={showCallDetails}
          setShowCallDetails={setShowCallDetails}
          customer={currentCustomer[0]}
          lastCallDetails={call}
        />
      )}
      <tr>
        <td>{index}.</td>
        <td>
          {currentCustomer[0]?.customerType === "Company"
            ? currentCustomer[0]?.companyName ?? "(deleted)"
            : currentCustomer[0]?.contactPersons[0]?.name ?? "(deleted)"}
        </td>
        <td>
          {currentCustomer[0]?.contactPersons[0]?.phoneNumber1 ?? "(deleted)"}
        </td>
        <td>{call?.status}</td>
        <td>
          {dateFormat(
            new Date(call?.createdAt?.seconds * 1000),
            "ddd, dd mmm  yyyy, hh:MM TT"
          )}
        </td>
        <td>{currentAgent[0]?.name ?? "(deleted)"}</td>
        <td>
          <Button variant="success" onClick={() => setShowCallDetails(true)}>
            View
          </Button>
        </td>
      </tr>
    </>
  );
};

export default memo(SingleCallHistoryRow);
