/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, memo } from "react";
import { useParams } from "react-router-dom";
import { db } from "../utils/firebase";
import Loader from "../components/Loader/Loader";
import SalesRepCallHistoryTable from "../components/History/SalesRepCallHistoryTable";
import { Container, Button, Form, Badge } from "react-bootstrap";
import dateFormat from "dateformat";
import "../styles/SalesRepresentatives.css";

const SalesRepCallHistory = () => {
  const [salesRepCalls, setSalesRepCalls] = useState([]);
  const [filters, setFilters] = useState([
    "Lead",
    "No Order",
    "Not Available",
    "Order Confirmed",
  ]);
  const [showFilters, setShowFilters] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(
    dateFormat(Date.now() - 27 * 86400000, "yyyy-mm-dd")
  );
  const [endDate, setEndDate] = useState(
    dateFormat(Date.now() + 86400000, "yyyy-mm-dd")
  );
  const { cid } = useParams();

  const fetchCalls = () => {
    db.collection("callhistory")
      .where("agent_id", "==", cid)
      .where("createdAt", ">", new Date(startDate))
      .where("createdAt", "<", new Date(endDate))
      .where("status", "in", filters)
      .onSnapshot((snapshot) => {
        setSalesRepCalls(snapshot.docs.map((doc) => doc.data()).reverse());
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let abortController = new AbortController();
    fetchCalls();
    return () => abortController.abort();
  }, []);

  const handleChange = (e) => {
    if (filters.length === 1 && filters.includes(e.target.value)) {
      return;
    } else if (filters.includes(e.target.value)) {
      setFilters(filters.filter((filter) => filter !== e.target.value));
    } else {
      setFilters([...filters, e.target.value]);
    }
  };

  return (
    <Container>
      <div>
        <div className="customer__title mb-2">
          <h3 className="title">
            Sales Representative Call History{" "}
            <Badge bg="success">{salesRepCalls?.length} calls</Badge>
          </h3>
          <Button variant="dark" onClick={() => setShowFilters(!showFilters)}>
            {showFilters ? "Hide Filters" : "Filters"}
          </Button>
        </div>
        {showFilters && (
          <div className="filters">
            <h3>Filters</h3>
            <Form.Group>
              <Form.Check
                inline
                value="Lead"
                label="Lead"
                type="checkbox"
                checked={filters.includes("Lead")}
                onChange={handleChange}
              />
              <Form.Check
                inline
                value="No Order"
                label="No Order"
                type="checkbox"
                checked={filters.includes("No Order")}
                onChange={handleChange}
              />
              <Form.Check
                inline
                value="Not Available"
                label="Not Available"
                type="checkbox"
                checked={filters.includes("Not Available")}
                onChange={handleChange}
              />
              <Form.Check
                inline
                value="Order Confirmed"
                label="Order Confirmed"
                type="checkbox"
                checked={filters.includes("Order Confirmed")}
                onChange={handleChange}
              />
            </Form.Group>
            <div className="mt-4 mb-1 d-flex align-items-center">
              <Form.Group className="d-flex align-items-center">
                <Form.Label
                  style={{ fontWeight: "500", margin: "0px 10px 0 0" }}
                >
                  Start Date
                </Form.Label>
                <Form.Control
                  style={{ width: 200 }}
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Form.Group>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Form.Group className="d-flex align-items-center">
                <Form.Label
                  style={{ fontWeight: "500", margin: "0px 10px 0 0" }}
                >
                  End Date
                </Form.Label>
                <Form.Control
                  style={{ width: 200 }}
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Form.Group>
            </div>
            <Button
              variant="dark"
              className="mb-4 mt-2"
              onClick={fetchCalls}
              disabled={!startDate || !endDate}
            >
              Apply
            </Button>
          </div>
        )}
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <SalesRepCallHistoryTable salesRepCalls={salesRepCalls} />
      )}
    </Container>
  );
};

export default memo(SalesRepCallHistory);
