/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, memo, useContext } from "react";
import { AuthContext } from "../context/authContext";
import { db } from "../utils/firebase";
import AddCustomers from "../components/Customers/AddCustomers";
import CustomersTable from "../components/Customers/CustomersTable";
import Loader from "../components/Loader/Loader";
import { Button, Container, Form, InputGroup } from "react-bootstrap";
import { BsPlusLg, BsSearch } from "react-icons/bs";
import { IoMdDownload } from "react-icons/io";
import "../styles/Customers.css";
import ExportModal from "../components/Customers/ExportModal";

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [allProductGroups, setAllProductGroups] = useState([]);
  const [filters, setFilters] = useState(["Company", "Individual"]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddingCustomer, setIsAddingCustomer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [salesReps, setSalesReps] = useState([]);
  const [showExportModal, setShowExportModal] = useState(false);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    let abortController = new AbortController();
    if (currentUser.role === "sales representative") {
      db.collection("customers")
        .orderBy("companyName", "asc")
        .where("addedById", "==", currentUser.id)
        .onSnapshot((snapshot) => {
          setCustomers(snapshot.docs.map((doc) => doc.data()));
          setFilteredCustomers(snapshot.docs.map((doc) => doc.data()));
          setIsLoading(false);
        });
    } else {
      db.collection("customers")
        .orderBy("companyName", "asc")
        .onSnapshot((snapshot) => {
          setCustomers(snapshot.docs.map((doc) => doc.data()));
          setFilteredCustomers(snapshot.docs.map((doc) => doc.data()));
          setIsLoading(false);
        });
    }
    return () => abortController.abort();
  }, []);

  useEffect(() => {
    let abortController = new AbortController();
    db.collection("productgroups")
      .orderBy("name", "asc")
      .onSnapshot((snapshot) => {
        setAllProductGroups(snapshot.docs.map((doc) => doc.data()));
      });
    return () => abortController.abort();
  }, []);

  const handleChange = (e) => {
    if (filters.includes(e.target.value)) {
      setFilters(filters.filter((filter) => filter !== e.target.value));
    } else {
      setFilters([...filters, e.target.value]);
    }
  };

  const applyFilters = () => {
    if (filters.length === 0) {
      setFilteredCustomers(customers);
      return;
    }
    let filtered;
    if (JSON.stringify(filters) === JSON.stringify(["Company"])) {
      filtered = customers.filter((customer) =>
        filters.includes(customer?.customerType)
      );
    } else if (JSON.stringify(filters) === JSON.stringify(["Individual"])) {
      filtered = customers.filter((customer) =>
        filters.includes(customer?.customerType)
      );
    } else if (
      JSON.stringify(filters) === JSON.stringify(["Company", "Individual"])
    ) {
      filtered = customers.filter((customer) =>
        filters.includes(customer?.customerType)
      );
    } else {
      filtered = customers.filter(
        (customer) =>
          filters.includes(customer?.customerType) &&
          filters.some((r) => customer?.interestedProductGroups.includes(r))
      );
    }
    setFilteredCustomers(filtered);
  };

  useEffect(() => {
    const abortController = new AbortController();
    db.collection("users")
      .where("role", "==", "sales representative")
      .onSnapshot((snapshot) => {
        setSalesReps(snapshot.docs.map((doc) => doc.data()));
      });

    return abortController.abort();
  }, []);

  return (
    <Container className="customer__container">
      <div className="customer__title">
        <h3 className="title">Customers</h3>
        <div>
          <Button variant="dark" onClick={() => setShowExportModal(true)}>
            <IoMdDownload size={20} />
            &nbsp;Export
          </Button>
          &nbsp;&nbsp;
          <Button onClick={() => setIsAddingCustomer(true)} variant="success">
            <p
              style={{ marginBottom: 0, display: "flex", alignItems: "center" }}
            >
              <BsPlusLg />
              &nbsp;&nbsp;Add Customer
            </p>
          </Button>
          &nbsp;&nbsp;
          <Button variant="dark" onClick={() => setShowFilters(!showFilters)}>
            {showFilters ? "Hide Filters" : "Filters"}
          </Button>
        </div>
      </div>

      {showFilters && (
        <div className="filters">
          <h3>Filters</h3>
          <Form.Group>
            <Form.Check
              inline
              value="Company"
              label="Company"
              type="checkbox"
              checked={filters.includes("Company")}
              onChange={handleChange}
            />
            <Form.Check
              inline
              value="Individual"
              label="Individual"
              type="checkbox"
              checked={filters.includes("Individual")}
              onChange={handleChange}
            />
          </Form.Group>
          <div className="checkbox__container mt-3">
            {allProductGroups?.map((pg) => (
              <Form.Check
                key={pg.id}
                inline
                value={pg.id}
                label={pg.name}
                type="checkbox"
                checked={filters.includes(pg.id)}
                onChange={handleChange}
              />
            ))}
          </div>
          <div className="filter__buttons">
            <Button variant="dark" onClick={applyFilters} className="btn">
              Apply Filters
            </Button>
          </div>
        </div>
      )}

      <InputGroup className="customer__Search">
        <InputGroup.Text className="bg-white">
          <BsSearch />
        </InputGroup.Text>
        <Form.Control
          type="text"
          placeholder="Search Customers"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>

      {isAddingCustomer && (
        <AddCustomers
          isAddingCustomer={isAddingCustomer}
          setIsAddingCustomer={setIsAddingCustomer}
        />
      )}

      {showExportModal && (
        <ExportModal
          show={showExportModal}
          onClose={() => setShowExportModal(false)}
          customers={customers}
          salesReps={salesReps}
        />
      )}

      {isLoading ? (
        <Loader />
      ) : (
        <CustomersTable
          customers={filteredCustomers}
          searchTerm={searchTerm}
          salesReps={salesReps}
        />
      )}
    </Container>
  );
};

export default memo(Customers);
