/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Card, Form, InputGroup } from "react-bootstrap";

export default function OrdersBelowPrice({ data }) {
  const [totalBelow, setTotalBelow] = useState(0);
  const [belowPrice, setBelowPrice] = useState(10000);

  const filterData = () => {
    const tempBelow = [];
    data?.forEach((d) => {
      const totalPrice = d.productsRequired.reduce((a, p) => {
        return a + parseInt(p.price.replace(",", ""));
      }, 0);
      if (totalPrice < belowPrice) {
        tempBelow.push(d);
      }
    });
    setTotalBelow(tempBelow.length);
  };

  useEffect(() => {
    filterData();
  }, []);

  return (
    <Card className="p-2">
      <div className="chart-title2">
        <h2>Orders Below Price</h2>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Tsh</InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Product group"
            value={belowPrice}
            onChange={(e) => setBelowPrice(e.target.value)}
          />
          <Button variant="success" onClick={filterData}>
            Find
          </Button>
        </InputGroup>
        <h2>{totalBelow} Orders</h2>
      </div>
      <div></div>
    </Card>
  );
}
