import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { CSVLink } from "react-csv";
import dateFormat from "dateformat";

export default function ExportModal({ show, onClose, customers, salesReps }) {
  const [selectedSalesRepId, setSelectedSalesRepId] = useState(salesReps[0].id);
  const [selectedSalesRep, setSelectedSalesRep] = useState(salesReps[0]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);

  const handleModalClose = () => {
    onClose();
  };

  useEffect(() => {
    setFilteredCustomers(
      customers
        ?.filter((customer) => customer?.addedById === selectedSalesRepId)
        .map((customer) => ({
          name:
            customer?.customerType === "Company"
              ? customer?.companyName
              : customer?.contactPersons[0]?.name,
          contact: customer?.contactPersons[0]?.phoneNumber1,
          cutomer_type: customer?.customerType,
          tin: customer?.TIN || "N/A",
          vrn: customer?.VRN || "N/A",
          address: `${customer?.address?.physical_address} ${customer?.address?.city} ${customer?.address?.country}`,
          added_by: selectedSalesRep?.name,
          added_on: dateFormat(
            new Date(customer?.createdAt?.seconds * 1000),
            "ddd, dd mmm  yyyy, hh:MM TT"
          ),
        }))
    );
    setSelectedSalesRep(salesReps.find((rep) => rep.id === selectedSalesRepId));
  }, [customers, salesReps, selectedSalesRep?.name, selectedSalesRepId]);

  return (
    <Modal show={show} onHide={handleModalClose} animation={false} centered>
      <Modal.Header
        closeButton
        style={{ borderBottom: "none", paddingBottom: 0 }}
      >
        <Modal.Title>Export Customer Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label>Select Sales Representative</label>
        <Form.Select
          aria-label="Default select example"
          value={selectedSalesRepId}
          onChange={(e) => setSelectedSalesRepId(e.target.value)}
        >
          <option disabled>Select One</option>
          {salesReps.map((salesRep) => (
            <option value={salesRep.id}>{salesRep?.name}</option>
          ))}
        </Form.Select>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" type="button" onClick={handleModalClose}>
          Close
        </Button>
        <CSVLink
          data={filteredCustomers}
          filename={`${selectedSalesRep?.name}_customers`}
        >
          <Button variant="dark" type="button" disabled={!selectedSalesRepId}>
            Export
          </Button>
        </CSVLink>
      </Modal.Footer>
    </Modal>
  );
}
