import { useState, useEffect, memo } from "react";
import { db } from "../utils/firebase";
import Loader from "../components/Loader/Loader";
import AddAdmins from "../components/Admins/AddAdmins";
import AdminsTable from "../components/Admins/AdminTable";
import { Button, Container } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";
import "../styles/Admins.css";

const Admins = () => {
  const [admins, setAdmins] = useState([]);
  const [isAddingAdmin, setIsAddingAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let abortController = new AbortController();
    db.collection("users")
      .where("role", "==", "admin")
      .orderBy("name", "asc")
      .onSnapshot((snapshot) => {
        setAdmins(snapshot.docs.map((doc) => doc.data()));
        setIsLoading(false);
      });
    return () => abortController.abort();
  }, []);

  return (
    <Container>
      <div className="admin__title">
        <h3 className="title">Admins</h3>
        <Button
          onClick={() => setIsAddingAdmin(!isAddingAdmin)}
          variant="success"
        >
          <p style={{ marginBottom: 0, display: "flex", alignItems: "center" }}>
            <BsPlusLg />
            &nbsp;&nbsp;Add Admin
          </p>
        </Button>
      </div>
      {isAddingAdmin && (
        <AddAdmins
          isAddingAdmin={isAddingAdmin}
          setIsAddingAdmin={setIsAddingAdmin}
        />
      )}
      {isLoading ? <Loader /> : <AdminsTable admins={admins} />}
    </Container>
  );
};

export default memo(Admins);
