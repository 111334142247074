import { memo, useContext, useState } from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
// import { AuthContext } from "../context/authContext";
import { DataContext } from "../context/dataContext";
import BusinessesGraph from "../components/Analytics/BusinessesGraph";
import CallsGraph from "../components/Analytics/CallsGraph";
import CustomersAddedgraph from "../components/Analytics/CustomersAddedGraph";
import IndividualsGraph from "../components/Analytics/IndividualsGraph";
import TopOrderingCustomers from "../components/Analytics/TopOrderingCustomers";
import CallsByStatusGraph from "../components/Analytics/CallsByStatusGraph";
import ConfirmedOrdersGraph from "../components/Analytics/ConfirmedOrderGraph";
import FailedOrdersGraph from "../components/Analytics/FailedOrdersGraph";
import CustomerCitiesgraph from "../components/Analytics/CustomerCitiesGraph";
import CustomersWithNoOrders from "../components/Analytics/CustomersWithNoOrders";
import { getDateNDaysAgo } from "../utils/helperFunctions";
import ScheduleCallsGraph from "../components/Analytics/ScheduleCallsGraph";
import OrdersAbovePrice from "../components/Analytics/OrdersAbovePrice";
import OrdersBelowPrice from "../components/Analytics/OrdersBelowPrice";
import Loader from "../components/Loader/Loader";

const Home = () => {
  // const { currentUser } = useContext(AuthContext);
  const { callsData, ordersData, customerData, activeCallData } =
    useContext(DataContext);
  const [key, setKey] = useState("customers");

  return (
    <Container>
      {/* <div style={{ textAlign: "left", marginBottom: 30 }}>
        <h3>Welcome, {currentUser.name} 👋😃</h3>
        <p>You are logged in as {currentUser.role}.</p>
      </div> */}

      {!customerData || !ordersData || !callsData || !activeCallData ? (
        <Loader />
      ) : (
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className="mb-3"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="customers" title="Customers">
            <Row className="mb-4">
              <Col>
                {customerData && <CustomersAddedgraph data={customerData} />}
              </Col>
              <Col>
                {customerData && (
                  <BusinessesGraph
                    data={customerData?.filter(
                      (d) => d.customerType === "Company"
                    )}
                  />
                )}
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                {customerData && (
                  <IndividualsGraph
                    data={customerData?.filter(
                      (d) => d.customerType === "Individual"
                    )}
                  />
                )}
              </Col>
              <Col>
                {customerData && <CustomerCitiesgraph data={customerData} />}
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                {ordersData && <TopOrderingCustomers data={ordersData} />}
              </Col>
              <Col>
                {ordersData && customerData && (
                  <CustomersWithNoOrders
                    title="Customers with No Orders"
                    orderData={ordersData.map((d) => d.customer_id)}
                    customerData={customerData}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {ordersData && customerData && (
                  <CustomersWithNoOrders
                    title="Customers who haven’t placed an order in a month"
                    orderData={ordersData
                      .filter(
                        (d) =>
                          new Date(d.createdAt.seconds * 1000) >
                          getDateNDaysAgo(30)
                      )
                      .map((d) => d.customer_id)}
                    customerData={customerData}
                  />
                )}
              </Col>
              <Col></Col>
            </Row>
          </Tab>

          <Tab eventKey="calls" title="Calls">
            <Row className="mb-4">
              <Col>
                {callsData && (
                  <CallsGraph
                    data={callsData}
                    title="Total Calls"
                    label="Calls Placed"
                  />
                )}
              </Col>
              <Col>{callsData && <CallsByStatusGraph data={callsData} />}</Col>
            </Row>
            <Row className="mb-4">
              <Col>
                {callsData && <ScheduleCallsGraph data={activeCallData} />}
              </Col>
              <Col>
                {callsData && (
                  <CallsGraph
                    data={callsData.filter((call) => call.status === "Lead")}
                    title="Total Leads"
                    label="Leads"
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {callsData && (
                  <CallsGraph
                    data={callsData.filter((d) => d.callback_date.seconds)}
                    title="Total Scheduled Calls"
                    label="Scheduled Calls"
                  />
                )}
              </Col>
              <Col>
                {callsData && (
                  <CallsGraph
                    data={callsData.filter(
                      (call) => call.status === "No Order"
                    )}
                    title="Total No Order Calls"
                    strokeColor="#D70040"
                    label="No Order Calls"
                  />
                )}
              </Col>
              <Col></Col>
            </Row>
          </Tab>

          <Tab eventKey="orders" title="Orders">
            <Row className="mb-4">
              <Col>
                {ordersData && <ConfirmedOrdersGraph data={ordersData} />}
              </Col>
              <Col>
                {callsData && (
                  <FailedOrdersGraph
                    data={callsData.filter(
                      (data) =>
                        data.isCallback === false && data.isRequirement === true
                    )}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col>{ordersData && <OrdersAbovePrice data={ordersData} />}</Col>
              <Col>{ordersData && <OrdersBelowPrice data={ordersData} />}</Col>
            </Row>
          </Tab>
        </Tabs>
      )}
    </Container>
  );
};

export default memo(Home);
