import { useEffect, useState } from "react";
import { Card, Form, Table } from "react-bootstrap";
import { db } from "../../utils/firebase";
import { getDateNDaysAgo } from "../../utils/helperFunctions";

export default function TopOrderingCustomers({ data }) {
  const [days, setDays] = useState(7);
  const [tableData, setTableData] = useState(undefined);

  const filterData = () => {
    const temp = data?.filter((d) => {
      return new Date(d.createdAt.seconds * 1000) >= getDateNDaysAgo(days);
    });

    const tempCust = {};
    temp.forEach((d) => {
      const cust = d.customer_id;
      if (!tempCust[cust]) {
        tempCust[cust] = 1;
      } else {
        tempCust[cust] += 1;
      }
    });
    let sorted = [];

    for (var i in tempCust) {
      sorted.push([i, tempCust[i]]);
    }
    sorted
      .sort(function (a, b) {
        return a[1] - b[1];
      })
      .reverse();

    setTableData(sorted.slice(0, 10));
  };

  useEffect(() => {
    filterData();
  }, [days]);

  return (
    <Card className="p-2">
      <div className="chart-title">
        <h2>Top 10 Most Ordering Customers</h2>
        <Form.Select
          value={days}
          onChange={(e) => setDays(e.target.value)}
          style={{ width: 200 }}
        >
          <option disabled value={-1}>
            Select Days
          </option>
          <option value={7}>Past 7 days</option>
          <option value={30}>Past 30 days</option>
          <option value={90}>Past 3 months</option>
          <option value={180}>Past 6 months</option>
          {/* <option value={-1}>So Far</option> */}
        </Form.Select>
      </div>

      <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
        <Table responsive striped bordered>
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>Name</th>
              <th>Type</th>
              <th>Total orders</th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((d, i) => (
              <TableRow
                key={d[0]}
                index={i + 1}
                cust_id={d[0]}
                totalOrders={d[1]}
              />
            ))}
          </tbody>
        </Table>
      </div>
    </Card>
  );
}

const TableRow = ({ index, cust_id, totalOrders }) => {
  const [customer, setCustomer] = useState(undefined);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const res = await db.collection("customers").doc(cust_id).get();
        setCustomer(res.data());
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchCustomer();
  }, []);

  return (
    <tr>
      <td>{index}.</td>
      <td>
        {customer?.customerType === "Company"
          ? customer?.companyName
          : customer?.contactPersons[0].name}
      </td>
      <td>{customer?.customerType}</td>
      <td>{totalOrders}</td>
    </tr>
  );
};
