import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../utils/firebase";
import { Container, Tabs, Tab } from "react-bootstrap";
import CustomersAdded from "../components/SalesRepresentatives/CustomersAdded";
import TotalCalls from "../components/SalesRepresentatives/TotalCalls";

const SalesRepresentativePerformance = () => {
  const [salesRepresentative, setSalesRepresentative] = useState({});
  const { cid } = useParams();

  useEffect(() => {
    const abortController = new AbortController();
    db.collection("users")
      .where("id", "==", cid)
      .onSnapshot((snapshot) => {
        setSalesRepresentative(snapshot.docs[0].data());
      });
    return abortController.abort();
  }, [cid]);

  return (
    <Container>
      <Tabs
        defaultActiveKey="confirmed"
        className="mb-0"
        id="noanim-tab-example"
      >
        <Tab eventKey="confirmed" title="Customers Added">
          <CustomersAdded salesRepresentative={salesRepresentative} />
        </Tab>
        <Tab eventKey="failed" title="Calls">
          <TotalCalls salesRepresentative={salesRepresentative} />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default SalesRepresentativePerformance;
