import { useEffect, useState } from "react";
import { Card, Form, Table } from "react-bootstrap";

export default function CustomersWithNoOrders({
  title,
  orderData,
  customerData,
}) {
  const [tableData, setTableData] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState("");

  const filterData = () => {
    const custData = [];
    customerData.forEach((d) => {
      if (!orderData.includes(d.id)) {
        custData.push(d);
      }
    });
    setTableData(custData);
  };

  useEffect(() => {
    filterData();
  }, []);

  return (
    <Card className="p-2">
      <div className="chart-title">
        <h2>
          {title} ({tableData?.length})
        </h2>
        <Form.Control
          type="text"
          placeholder="Search Customers"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: 250 }}
        />
      </div>

      <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
        <Table responsive striped bordered>
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>Name</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((d, i) => {
              if (
                d.companyName
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()) ||
                d.contactPersons[0]?.name
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              ) {
                return (
                  <tr key={d.id}>
                    <td>{i + 1}.</td>
                    <td>
                      {d?.customerType === "Company"
                        ? d?.companyName
                        : d?.contactPersons[0].name}
                    </td>
                    <td>{d?.customerType}</td>
                  </tr>
                );
              } else {
                return null;
              }
            })}
          </tbody>
        </Table>
      </div>
    </Card>
  );
}
