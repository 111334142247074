/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, memo, useCallback, useContext } from "react";
import { db } from "../../utils/firebase";
import FeedbackModal from "../Calls/FeedbackModal";
import EditCustomers from "./EditCustomers";
import CustomerDetails from "./CustomerDetails";
import ConfirmBox from "../ConfirmBox/ConfirmBox";
import CustomerRow from "./CustomerRow";
import { Table } from "react-bootstrap";
import { AuthContext } from "../../context/authContext";

const ALL_CATEGORIES = {};

const CustomersTable = ({ customers, searchTerm, salesReps }) => {
  const [isEditingCustomer, setIsEditingCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(-1);
  const [allInterestedProductGroups, setAllInterestedProductGroups] = useState(
    {}
  );
  const [showDetails, setShowDetails] = useState(false);
  const [isCall, setIsCall] = useState(false);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [allCategories, setAllCategories] = useState(undefined);
  const { fetchUniqueTins, fetchUniquePhoneNumbers } = useContext(AuthContext);

  const deleteCustomer = useCallback((id) => {
    db.collection("customers")
      .doc(id)
      .delete()
      .then(() => {
        const res1 =
          customers.length === 1
            ? []
            : customers
                .filter((customer) => customer.id !== id)
                .map((customer) => customer.TIN);
        db.collection("unique")
          .doc("tin")
          .update({
            value: res1,
          })
          .then(() => {
            fetchUniqueTins();
          });
        const res2 =
          customers.length === 1
            ? []
            : customers
                .filter((customer) => customer.id !== id)
                .map((customer) => customer.contactPersons[0].phoneNumber1);
        db.collection("unique")
          .doc("phone")
          .update({
            value: res2,
          })
          .then(() => {
            fetchUniquePhoneNumbers();
          });
      })
      .catch((err) => {
        console.log(err.message);
      });
    setShowConfirmBox(false);
  }, []);

  useEffect(() => {
    let abortController = new AbortController();
    db.collection("productgroups")
      .orderBy("name", "asc")
      .onSnapshot((snapshot) => {
        snapshot.docs.forEach((doc) => {
          const { id, name } = doc.data();
          ALL_CATEGORIES[id] = name;
        });
        setAllCategories(snapshot.docs.map((doc) => doc.data()));
        setAllInterestedProductGroups(ALL_CATEGORIES);
      });
    return () => abortController.abort();
  }, []);

  console.log(customers);

  return (
    <>
      {/* All Modals */}
      {isEditingCustomer && (
        <EditCustomers
          show={isEditingCustomer}
          customer={customers[selectedCustomer]}
          setIsEditingCustomer={setIsEditingCustomer}
          allCategories={allCategories}
        />
      )}
      {showDetails && (
        <CustomerDetails
          show={showDetails}
          customer={customers[selectedCustomer]}
          setShowDetails={setShowDetails}
          allInterestedProductGroups={allInterestedProductGroups}
        />
      )}
      {isCall && (
        <FeedbackModal
          show={isCall}
          setIsCall={setIsCall}
          customer={customers[selectedCustomer]}
          isNewCall={true}
        />
      )}
      {showConfirmBox && (
        <ConfirmBox
          show={showConfirmBox}
          setShowConfirmBox={setShowConfirmBox}
          deleteFunction={deleteCustomer}
          id={customers[selectedCustomer]?.id}
          text="Are you sure you want to delete this?"
        />
      )}
      {/* Table starts here */}
      <Table responsive striped bordered>
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Name</th>
            <th>Contact</th>
            <th>Customer Type</th>
            <th>Actions</th>
            <th>Details</th>
            <th>Call History</th>
            <th>Interested Product Groups</th>
            <th>AddedBy</th>
          </tr>
        </thead>
        <tbody>
          {customers &&
            customers?.map((customer, index) => (
              <CustomerRow
                key={customer?.id}
                customer={customer}
                index={index}
                setIsEditingCustomer={setIsEditingCustomer}
                setSelectedCustomer={setSelectedCustomer}
                setIsCall={setIsCall}
                setShowDetails={setShowDetails}
                setShowConfirmBox={setShowConfirmBox}
                allInterestedProductGroups={allInterestedProductGroups}
                searchTerm={searchTerm}
                salesReps={salesReps}
              />
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default memo(CustomersTable);
